import * as React from 'react';
import { Modal as AntModal } from 'antd';

import './modal.css';

const Modal = ({
  title = '',
  open,
  onOk = () => {},
  onCancel = () => {},
  footer,
  children,
  className = '',
  mask = true,
  maskClosable = true,
  destroyOnClose = true,
  closeIcon = true,
}) => {
  return (
    <AntModal
      className={className}
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer}
      getContainer="#modal-root"
      mask={mask}
      maskClosable={maskClosable}
      destroyOnClose={destroyOnClose}
      closeIcon={closeIcon}
    >
      {children}
    </AntModal>
  );
};

export default Modal;
