import { useEffect, useRef } from 'react';

import { setIsMessagesWindowScrolling } from 'stores/app-context';

import ConversationMessageRow from '../ConversationMessageRow';
import ConversationResponseRow from '../ConversationResponseRow';
import ResponseFeedback from '../ResponseFeedback';
import ResubmitButton from '../ResubmitButton';

import './conversation-message-display.css';

type ConversationMessageDisplayProps = {
  messages: any[];
  messagesEndRef: any;
  resubmitAction?: (errorMessageId) => void;
  currentConversationId?: string;
};

const ConversationMessageDisplay = ({
  messages,
  messagesEndRef,
  resubmitAction,
  currentConversationId,
}: ConversationMessageDisplayProps) => {
  const messagesWindowRef = useRef<HTMLDivElement | null>(null);
  const handleScrollRef = useRef<() => void>(() => {});

  handleScrollRef.current = () => {
    if (messagesWindowRef.current) {
      setIsMessagesWindowScrolling(messagesWindowRef.current.scrollTop > 50);
    }
  };

  useEffect(() => {
    const messagesWindow = messagesWindowRef.current;

    if (!messagesWindow || !handleScrollRef.current) {
      return;
    }

    const handleScroll = () => {
      handleScrollRef.current();
    };

    messagesWindow.addEventListener('scroll', handleScroll);

    return () => messagesWindow.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div ref={messagesWindowRef} className="messages-window">
      {messages.map((m, i) => (
        <div key={`message_${m.messageId}${'_' + m.generationStatus}`}>
          {i % 2 === 0 && (
            <ConversationMessageRow
              message={m.message}
              profileImg={m.profileImg}
            />
          )}
          {i % 2 !== 0 && (
            <>
              <ConversationResponseRow
                message={m.message}
                profileImg={m.profileImg}
                isError={m.generationStatus === 'error'}
              />
              {m.generationStatus === 'done' && (
                <ResponseFeedback
                  defaultReaction={m.userFeedback?.feedbackType}
                  conversationId={currentConversationId}
                  message={m}
                  updateReaction={() => {}}
                />
              )}
              {m.generationStatus === 'error' &&
                m.allowRetry &&
                resubmitAction && (
                  <ResubmitButton resubmitAction={resubmitAction} />
                )}
            </>
          )}
          <div ref={messagesEndRef} />
        </div>
      ))}
    </div>
  );
};

export default ConversationMessageDisplay;
