import { useEffect, useState } from 'react';
import { Input } from 'antd';
import { Button } from 'ui/atoms';
import DOMPurify from 'dompurify';

import {
  countStringNewLines,
  encodeStringToHtmlEntities,
} from 'utils/string-utils';
import { ReactComponent as QuestionInputIcon } from './submit-button.svg';

import './question-input.css';
import { CurrentConversationStore } from 'stores/current-conversation';

const { TextArea } = Input;

const QUESTION_LENGTH_MAX_IN_CHARS = 10000;

type QuestionInputProps = {
  onSubmit: (question: string) => void;
  value?: string;
};

const QuestionInput: React.FC<QuestionInputProps> = ({
  onSubmit,
  value = '',
}) => {
  const [questionValue, setQuestionValue] = useState(value);
  const [textareaRows, setTextareaRows] = useState(1);
  const [isSubmitable, setIsSubmitable] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isMultiline, setIsMultiline] = useState(true);
  const isStreaming = CurrentConversationStore.useState((s) => s.isStreaming);
  const isConversationLoading = CurrentConversationStore.useState(
    (s) => s.isLoading,
  );

  useEffect(() => {
    setDisableSubmit(isStreaming || !isSubmitable);
  }, [isSubmitable, isStreaming]);

  useEffect(() => {
    const charactersPerRow = 75;
    const questionLength = questionValue.length;
    const rows = Math.ceil(questionLength / charactersPerRow);
    const hasNewlines = questionValue.includes('\n');

    setTextareaRows(1);
    setIsSubmitable(false);
    setIsMultiline(false);

    if (questionLength) {
      setIsSubmitable(true);
    }

    if (questionLength >= QUESTION_LENGTH_MAX_IN_CHARS) {
      setQuestionValue(questionValue.slice(0, QUESTION_LENGTH_MAX_IN_CHARS));
    }

    if (hasNewlines) {
      const newLines = countStringNewLines(questionValue);
      setIsMultiline(true);
      setTextareaRows((prev) => prev + newLines);
    }

    if (rows > 1 && !hasNewlines) {
      setIsMultiline(true);
      setTextareaRows(rows);
    }
  }, [questionValue]);

  useEffect(() => {
    if (!isMultiline) {
      setTextareaRows(1);
    }
  }, [isMultiline]);

  useEffect(() => {
    if (!isConversationLoading) {
      return;
    }
    setQuestionValue('');
  }, [isConversationLoading]);

  const submit = () => {
    if (!questionValue?.length) {
      return;
    }

    const formatted = encodeStringToHtmlEntities(questionValue);
    onSubmit(DOMPurify.sanitize(formatted));
    setQuestionValue('');
  };

  return (
    <div className="conversation-box">
      <div className={`conversation ${isMultiline ? 'multiline' : ''}`}>
        <TextArea
          placeholder="Ask me anything..."
          value={questionValue}
          rows={textareaRows}
          onChange={(e) => {
            setQuestionValue(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              !disableSubmit && submit();
            }
          }}
          data-testid="question-input-textarea"
        ></TextArea>
        <Button
          type="text"
          shape="circle"
          className={`${!disableSubmit ? '' : '-disabled'}`}
          disabled={disableSubmit}
          onClick={submit}
          data-testid="question-input-button"
          icon={<QuestionInputIcon />}
        ></Button>
      </div>
      <span
        className={`input-character-limit ${
          questionValue.length > 0 ? '-show' : ''
        }`}
        data-testid="character-limit-warning"
      >
        {questionValue.length}/{QUESTION_LENGTH_MAX_IN_CHARS}
      </span>
    </div>
  );
};

export default QuestionInput;
