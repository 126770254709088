import React from 'react';
import { Button, Modal } from '../../atoms';

import FileUpload from './FileUpload';
import {
  FileUploadStore,
  deleteFile,
  resetStore as resetFileStore,
  saveMetadata,
} from 'stores/file-upload';
import { initPersonalFiles } from 'stores/user';
import { setGlobalMessage } from 'stores/global-message';
import { AccessLevel } from 'stores/metadata';

const FileUploadModal = ({ fileUploadVisible, setFileUploadVisible }) => {
  const isValid = FileUploadStore.useState((s) => s.isValid);
  const hasError = FileUploadStore.useState((s) => s.hasError);
  const fileId = FileUploadStore.useState((s) => s.fileId);
  const uploadComplete = FileUploadStore.useState((s) => s.uploadComplete);
  const accessLevel = FileUploadStore.useState((s) => s.accessLevel);

  const [hasSaved, setHasSaved] = React.useState(false);

  return (
    <Modal
      title={'Upload File'}
      open={fileUploadVisible}
      onOk={() => {
        setFileUploadVisible(false);
        resetFileStore();
      }}
      onCancel={() => {
        if (!hasSaved) {
          if (fileId) {
            deleteFile(fileId);
          }
        }
        setFileUploadVisible(false);
        resetFileStore();
      }}
      footer={[
        <Button
          key="fileupload_cancel"
          type="outline"
          onClick={() => {
            if (fileId) {
              deleteFile(fileId);
            }
            setFileUploadVisible(false);
            resetFileStore();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="fileupload_apply"
          type="default"
          className={`${isValid && uploadComplete ? 'active' : 'unavailable'}`}
          disabled={!isValid || hasError || !uploadComplete}
          onClick={() => {
            const shouldThank = accessLevel === AccessLevel.Public;
            setHasSaved(true);
            saveMetadata();
            setFileUploadVisible(false);
            initPersonalFiles();
            resetFileStore();
            if (shouldThank) {
              setGlobalMessage(
                'Thank you for taking the time to improve Goldie',
              );
            }
          }}
        >
          Save File
        </Button>,
      ]}
    >
      <FileUpload />
    </Modal>
  );
};

export default FileUploadModal;
