import { Button as AntButton } from 'antd';

import './button.css';

type ButtonType = 'text' | 'link' | 'default' | 'outline' | undefined;

type AntButtonType =
  | 'primary'
  | 'text'
  | 'link'
  | 'default'
  | 'dashed'
  | undefined;

interface ButtonProps {
  type?: ButtonType;
  children?: React.ReactNode;
  onClick?: (e?) => void;
  shape?: 'circle' | 'round';
  isActive?: boolean;
  testId?: string;
  [x: string]: any;
  // other props...
}

const mapTypeToClassName = (type: ButtonType): AntButtonType => {
  if (type === 'outline') {
    return 'dashed';
  }
  return type;
};

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  type = 'default',
  shape = 'round',
  isActive = false,
  ...rest
}) => {
  return (
    <AntButton
      onClick={(e) => {
        if (onClick && typeof onClick === 'function') {
          onClick(e);
        }
      }}
      shape={shape}
      type={mapTypeToClassName(type)}
      className={isActive ? '-active' : ''}
      {...rest}
    >
      {children}
    </AntButton>
  );
};

export default Button;
