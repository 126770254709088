import { Button } from 'antd';
import { ReactComponent as File } from './file.svg';
import { ReactComponent as Upload } from './upload.svg';

import './conversationcontrols.css';

const ConversationControls = ({ onSelectFileClick, onFileUploadClick }) => {
  return (
    <div className="conversation-controls">
      <Button
        type="dashed"
        data-testid="test-select-file-button"
        className="select-files-btn"
        onClick={onSelectFileClick}
      >
        <File /> Select files
      </Button>
      <Button
        type="dashed"
        className="upload-btn"
        data-testid="test-file-upload-button"
        onClick={onFileUploadClick}
      >
        <Upload /> Upload file
      </Button>
    </div>
  );
};

export default ConversationControls;
