import { UserStore } from 'stores/user';

import './conversation-message-row.css';

const ConversationMessageRow = ({ message, profileImg }) => {
  const initials = UserStore.useState((s) => s.userInfo?.initials || '!!');
  return (
    <div className="message -user">
      <div className="initials">{initials}</div>
      <div
        data-testid="user-message"
        className="message-content"
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
    </div>
  );
};

export default ConversationMessageRow;
